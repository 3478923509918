/* Home.css */

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 60vh;
  padding: 20px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #1a1a1a;
  /* Very dark purple background color */
  color: #fff;
  /* Text color */
}

.container {
  width: 80%;
  margin: 0 auto;
}

.game-section {
  text-align: center;
}

.start_button {
  padding: 10px 20px;
  font-size: 5rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}