/* Template.css */

body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #1a1a1a;
  color: #fff;
}
main {
  flex: 1; /* Take remaining vertical space */
  max-width: 800px; /* Adjust the maximum width as needed */
  margin: 0 auto; /* Center the main content */
  padding: 20px; /* Add padding for better readability */
}

.template {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure at least viewport height */
}

header {
  background-color: #333;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
}

footer {
  text-align: center;
  margin-top: 20px;
  padding: 10px;
  background-color: #333;
  color: white;
} 

